
const searchParams = new URLSearchParams(window.location.search);
const isPWA = window.matchMedia('(display-mode: standalone)').matches;
// var isPWA = true;

if (isPWA) {

    // push init history event so we can listen to on "popstate"
    window.history.pushState(null, '');

    // handle events on back or forward
    window.addEventListener('pageshow', (event) => {
        // hide sidebar on show
        if (AppElem) {
            AppElem.classList.remove("app-sidebar-mobile-toggled");
        }
    });

    window.addEventListener('popstate', (event) => {

        // close sidemenu instead of going back
        if (AppElem && AppElem.classList.contains("app-sidebar-mobile-toggled")) {
            AppElem.classList.remove("app-sidebar-mobile-toggled");
            window.history.pushState(null, '', `#sidebar-${Date.now()}`);
            return;
        }

        // Close modal instead of going back
        const modal = document.getElementById("filterByMonthModal");
        if (modal && modal.classList.contains("show")) {
            modal.querySelector("button.btn-close").click();
            window.history.pushState(null, '', `#modal-${Date.now()}`);
            return;
        }

        // Close Top chart instead of going back
        const cardExpanded = document.getElementsByClassName("card-expand")
        if (cardExpanded) {
            let expandButton = document.querySelector(".card.card-expand").querySelector(".bi.bi-fullscreen");
            expandButton.click()
            window.history.pushState(null, '', `#expand-${Date.now()}`);
            return;
        }



        // // prevent app from exit
        // if (searchParams.get('reset_app') == 'true') {
        //     window.close()
        //     return
        // }

        // to skip initial added init event, we go back twice (one by us one by browser)
        window.history.back();

    });

    if (searchParams.get('reset_app') == 'true') {
        window.history.go(-(window.history.length - 1));
    }

}


const registerServiceWorker = async () => {
    const metaTag = document.querySelector("meta[name='my-pwa-js']");
    if (metaTag) {
        if ("serviceWorker" in navigator) {
            const swURL = metaTag.content;
            try {
                const registration = await navigator.serviceWorker.register(swURL, {
                    scope: "/",
                });
                if (registration.installing) {
                    console.log("Service worker installing");
                } else if (registration.waiting) {
                    console.log("Service worker installed");
                } else if (registration.active) {
                    console.log("Service worker active");
                }
                registration.update();
            } catch (error) {
                console.error(`Registration failed with ${error}`);
            }
        }
    } else {
        console.error("Could not find pwa meta tag");
    }
};

// setup Elem that will be used to detect if app has sidemenu toggled
var AppElem;
window.addEventListener('DOMContentLoaded', () => {
    AppElem = document.getElementById("app");
    registerServiceWorker();
});